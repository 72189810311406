.parent {
    margin-top: 50px;
}

.title {
    font-family: "Roboto" !important;
    margin-bottom: 20px;
}

.breakdown-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.toggle-button {
    margin-bottom: 10px;
}

.component-container {
    height: auto;
    border-radius: 10px;
}

.button-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.toggle-title {
    color: #0061A0;
    font-family: 'Roboto';
    font-weight: 600;
}

.ant-switch.ant-switch-checked {
    background: #00AEEF !important;
}