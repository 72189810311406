.selected-care-homes-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.filter-label {
    margin-right: 8px; 
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 18px;
}

.care-home-tag {
    background-color: #6AC17B;
    margin-right: 20px;
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 20px;
}

.ant-tag-close-icon {
    font-size: 13px !important;
    margin-left: 10px !important;
    font-weight: bold !important;
    color: black !important;
}