.dashboard-header {
    margin-bottom: 50px;
    .ant-col {
        & > div {
            display: flex;
        }
        .dropdown-container {
            position: relative;
        }
    
        .button {
            min-height: 40px;
            min-width: 200px;
            border-radius: 10px;
            border: 1px solid #20639B;
            margin-right: 40px;
            color: #20639B;
        }
    
        .caret-container {
            border-left: 1px solid #0061A0;
            margin-left: 30px;
            padding-left: 5px;
        }
    
        .custom-dropdown {
            position: absolute;
            top: 50px;
            background-color: white;
            border: 2px solid #00AEEF !important;
            z-index: 10;
            width: 800px;
            border-radius: 5px;
            overflow: hidden;
            height: 470px;
        }
        .radio-group {
            display: flex;
            flex-direction: column;
            border-right: 1px solid #ccc;
            gap: 10px;
            height: 109%; 
            padding: 30px 20px;
        }
        .time-input-container {
            border-left: 1px solid #ccc;
            display: flex;
            padding: 30px 20px;
            flex-direction: column;
            height: 109%; 
            gap: 10px;
        }
        .date-container {
            display: flex;
            flex-direction: column;
            align-items: center; 
            text-align: left;
        }
        
        .date-title {
            align-self: flex-start;
            font-family: 'Roboto';
            margin-top: 30px;
            margin-bottom: 20px; 
            margin-left: 40px;
        }
        
        .containerOne {
            border-top: #ccc 1px solid !important;
            margin-top: 30px;
            display: flex;
            gap: 20px;
        }

        .resetButton {
            border-radius: 10px;
            border: 2px solid #0079C1;
            color: #0079C1;
            margin-left: 20px;
            width: 250px;
            height: 50px;
        }

        .applyButton {
            border-radius: 10px;
            background-color: #0079C1;
            color: white;
            width: 250px;
            height: 50px;
        }

        .date {
            margin-top: -20px;
        }

        .time-title {
            font-family: 'Roboto';
        }

        .label {
            font-family: 'Roboto';
            font-weight: 500;
            color: #2B2D42;
            font-size: 16px;
        }
        
        .rmdp-week-day {
            color: #00AEEF !important;
        }

        .rmdp-arrow-container {
            background-color: #00AEEF !important;
            height: 30px !important;
            width: 30px !important;
            margin: 0 10px !important;
        }

        .rmdp-arrow {
            border-color: rgb(255, 255, 255) !important;
            height: 10px !important;
            width: 10px !important;
            margin-top: 10px !important;
        }

        .rmdp-range {
            background-color: #0079C1 !important;
            color: white !important;
        }

        
        .rmdp-day.rmdp-today span {
            background-color: #0079C1 !important;
            color: white !important;
        }

        .rmdp-header-values {
            font-weight: 500;
            font-family: 'Roboto';
            font-size: 18px;
        }

        .time-input::-webkit-calendar-picker-indicator {
            opacity: 0;
            position: absolute;
            width: 100%; 
            height: 100%;  
            z-index: 1; 
        }
        
        .time-input {
            width: 100%; 
            padding-right: 30px;  
            border-color: #20639B;
        }
        
        .custom-time-input {
            position: relative;
            display: flex;
            align-items: center;
        }
        
        .down-arrow-icon {
            position: absolute;
            right: 10px; 
            pointer-events: none;  
        }
    }
}
