.ant-table-thead th {
    background-color: #0079C1 !important;
    color: white !important;
}

.even-row {
    background-color: #d5e7f5;
}

.ant-table-tbody > tr:not(:hover) > td {
    background-color: inherit !important;
}


.ant-tooltip .ant-tooltip-arrow {
    &::before {
        background-color: #00AEEF !important;
    }
    &::after {
        background-color: #00AEEF !important;
    }
}

