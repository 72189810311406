.my-account {
    .header {
        font-family: 'Roboto';
        font-size: 24px;
    }

    .account-details {
        background-color: #fff;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        .detail-item {
            flex: 1;

            &:not(:last-child) {
                margin-right: 5px;
            }

            .title-header {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                font-family: 'Roboto';
            }

            p {
                margin: 5px 0 0 0;
                font-size: 14px;
                font-family: 'Roboto';
            }
        }
    }

    // Table header styling
    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .table-title {
            margin: 0;
            font-size: 20px;
            font-family: 'Roboto';
        }

        .ant-btn-primary {
            background-color: #0079C1;
            font-family: 'Roboto';
            padding: 8px 30px;
            padding-bottom: 20px;
            padding-top: 20px;
            border-radius: 11px;

            &:hover {
                background-color: #0079C1;
                border-color: #0079C1;
            }

            .button-primary {
                color: #fff;
                font-size: 15px !important;
            }
        }
    }
}

.ant-dropdown-menu {
    width: 200px;

    .ant-dropdown-menu-item {
        padding: 10px 20px !important;
    }

    .ant-dropdown-menu-item:not(:last-child) {
        border-bottom: 1px solid #e8e8e8;
    }
}
