.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background-color: #fff;
    .ant-spin-dot-item {
        background-color: #0079C1;
    }
}