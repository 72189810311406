@import "../../../styles/elements";

.sub-title {
    font-size: 16px;
    width: 400px;
    font-family: 'Roboto';
    line-height: 1.71;
    margin-top: -20px;
    margin-bottom: 36px;
}
.password-criteria {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        font-size: 15px;
        line-height: 1.71;
        margin-bottom: 10px;
        padding: 0;
    }
}
.container-one {
    background-color: #FAFAFA;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 20px 0 20px 20px;
}

.password-requirements {
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 1.71;
}

.save-btn {
    background-color: #0079C1;
      color: $color-white;
      border-radius: 10px;
      height: 50px !important;
      margin: 20px 0 10px;
      &:hover {
        background-color: #0079C1;
        color: $color-white !important;
      }
}

.button-link-one {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    text-decoration: underline;
    color: $primary-button-color;
    font-size: medium !important;
    &:hover,
    &:focus {
        text-decoration: underline; 
    }
}