.chart-container {
  margin-top: 50px;
}

.chart-wrapper {
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
}

.cards-row {
    margin-top: 40px;
  }
  
  .custom-card {
    border-radius: 10px;
    box-shadow: 0px 0px 8px 2px rgba(34, 29, 35, 0.05);
  
    .ant-card-head {
      border-bottom: none;
    }

    .ant-card-head-title {
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 25px;
        margin-top: 10px;
        margin-bottom: 0 !important;
    }

    .timeline {
        margin-top: -20px;
        font-family: 'Roboto';
        font-size: 18px;
        font-weight: 500;
        text-align: left;
    }

    .number {
        font-family: 'Roboto';
        font-size: 55px;
        font-weight: 800;
        line-height: 64.45px;
        letter-spacing: 0.02em;
        color: #0061A0;
        margin-top: 3px;
    }
  }
