@import "../../../styles/elements";

.header {
  z-index: 1;
  &.floating-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .header__inner {
    background-color: $header-bg-color;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid #F0F0F0;
    line-height: normal;
    color: $primary-color;
    padding: 0 16px;
    height: 75px;
    .backlink {
      .icon {
        font-size: 32px;
      }
    }
    .ant-row-flex {
      .ant-col {
        &:first-child {
          > :first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  a {
    color: #77787B;
    text-decoration: none;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 16px;  
  }
  .active-link {
    color: #0061A0; 
    font-weight: 600;
  }  
  .icon {
    fill: currentColor;
  }

  .icon-menu {
    font-size: 48px;
    margin-right: 6px;
  }

  .icon-bell {
    font-size: 19px;
  }

  .icon-new-order {
    font-size: 16px;
  }
  .toolbar-button {
    background: transparent;
    border: none;
    &:hover {
      background: transparent;
      border: none;
    }
  }
}

.logo {
  margin-left: 50px;
}
