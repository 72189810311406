.overview {
    .header {
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 20px;
        margin-top: 30px;
    }
    .overview-card {
        height: 300px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        width: 100%; 
        box-sizing: border-box; 
    
        .card-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            width: 100%;
            .icon-circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                background-color: #0061A0;
                border-radius: 50%;
                color: white;
                margin: 0 auto; 
            }
            .card-details {
                font-weight: bold;
                margin-top: 20px; 
                text-align: center; 
    
                .number {
                    font-size: 35px;
                    margin-bottom: 10px;
                    font-weight: 700;
                    color: #0061A0;
                    font-family: 'Roboto';
                }
            }
        }
    }
    
    .circle-section {
        margin-top: 30px;
        .circle-container {
            display: flex;
            align-items: center;
            .circle-text {
                margin-left: 30px;
                display: flex;
                flex-direction: column;
            }
            .text {
                font-size: 23px;
                font-weight: 700;
                font-family: 'Roboto';
            }
            .number {
                font-size: 35px;
                font-weight: 700;
                color : #0061A0;
                font-family: 'Roboto';
            }
            .date-text {
                font-size: 18px;
                font-weight: 500;
                font-family: 'Roboto';
            }
        }
    }
}
