.header {
  z-index: 1;
}
.header.floating-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.header .header__inner {
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 2px solid #F0F0F0;
  line-height: normal;
  color: #20639B;
  padding: 0 16px;
  height: 75px;
}
.header .header__inner .backlink .icon {
  font-size: 32px;
}
.header .header__inner .ant-row-flex .ant-col:first-child > :first-child {
  margin-right: 10px;
}
.header a {
  color: #77787B;
  text-decoration: none;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}
.header .active-link {
  color: #0061A0;
  font-weight: 600;
}
.header .icon {
  fill: currentColor;
}
.header .icon-menu {
  font-size: 48px;
  margin-right: 6px;
}
.header .icon-bell {
  font-size: 19px;
}
.header .icon-new-order {
  font-size: 16px;
}
.header .toolbar-button {
  background: transparent;
  border: none;
}
.header .toolbar-button:hover {
  background: transparent;
  border: none;
}

.logo {
  margin-left: 50px;
}

