.add-care-home-user-modal {
    .ant-input {
        height: 50px !important;
        border-color: #83ACCF !important;
        padding: 0 15px !important;
    }
    .ant-select-selector {
        height: 50px !important;
        border-color: #83ACCF !important;
        padding: 0 15px !important;
    }
    .ant-select.ant-select-in-form-item {
        margin-bottom: 20px !important;
    }
    .ant-input:hover, .ant-select-selector:hover {
        border-color: #83ACCF !important;
        box-shadow: none !important;
    }
    .ant-select-arrow {
        color: #83ACCF !important;
        margin-top: 2px !important;
        font-size: 15px !important;
        font-weight: bold !important;
    }

    .ant-modal-footer {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-btn {
            height: 50px !important;
            line-height: 40px !important;
            width: 100%;
            border-radius: 11px !important;
        }

        .ant-btn-primary {
            background-color: #0079C1;
            border-color: #0079C1;
        }

        .ant-btn:not(.ant-btn-primary) {
            background-color: #fff;
            border-color: #0079C1;
            color: #0079C1;
            margin-inline-start: 0px !important;
        }
    }
}
