.checkbox-container {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active svg, &.inactive svg {
        animation: bounce 0.5s ease;
    }
}

@keyframes bounce {
    0%, 100% {
       transform: scale(1);
    }
    50% {
       transform: scale(0.85);
    }
}
