@import "./elements";
/* Ant design overrides */
#root {
  .ant-btn-link {
    color: $color-sky-blue;
    svg {
        font-size: 20px;
    }
    :hover {
      color: $color-sky-blue;
    }
  }
  [class^="ant-btn"] {
    font-size: 18px;
    &.login-btn {
      background-color: #0079C1;
      color: $color-white;
      border-radius: 10px;
      margin-top: 20px;
      height: 50px;
      &:hover {
        background-color: #0079C1;
      }
    }
    &.button-link {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 30px;
      text-decoration: underline;
      color: $primary-button-color;
      font-size: medium !important;
      &:hover,
      &:focus {
          text-decoration: underline; 
      }
    }
    &.download-button {
      background-color: #0079C1;
      color: $color-white;
      padding-left: 50px;
      border-radius: 10px;
      font-size: 18px;
      font-family: 'Roboto';
      height: 45px;
      &:hover {
        background-color: #0079C1;
      }
    }

    &:is(.ant-btn-background-ghost) {
      &:hover {
        color: $primary-color;
        background-color: #fafafa;
        border-color: $primary-color;
      }
    }
    &:is(:disabled) {
      background-color: #EDF2F4;
      border-color: #EDF2F4;
      color: #C0D1D8;

      &:hover {
        background-color: #EDF2F4;
        border-color: #EDF2F4;
        color: #C0D1D8;
      }
    }
  }
}
body {
  font-size: 14px;
  background-color: $body-bg-color;
  font-family: $primary-font-family;
}

.p-1 { padding: 4px}
.p-2 { padding: 8px}
.p-4 { padding: 16px}
.p-8 { padding: 32px}
.p-12 { padding: 48px}
.p-16 { padding: 64px}

.mb-1 { margin-bottom: 4px}
.mb-2 { margin-bottom: 8px}
.mb-4 { margin-bottom: 16px}
.mb-8 { margin-bottom: 32px}
.mb-16 { margin-bottom: 64px}

.mt-1 { margin-top: 4px}
.mt-2 { margin-top: 8px}
.mt-4 { margin-top: 16px}
.mt-8 { margin-top: 32px}
.mt-16 { margin-top: 64px}

.mlp-25 { margin-left: 25% }

.title-text {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title;
}
.title-text-md {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-title-md;
}

.ant-btn-primary {
  background: $primary-button-color;
  border-radius: 0px 4px 4px 0px;
}

.ant-btn-lg {  height: 55px; }

.ant-btn-primary:hover, .ant-btn-primary:focus {
    border-color: $primary-button-color;
    background:  $primary-button-color;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 40px;
  padding: 20px 15px;
  border-radius: 7px;

}

.ant-btn-link {
  color: $primary-color;
}

.ant-input-affix-wrapper {
  height: 50px;
  border-radius: 7px;
}

.ant-input {
  height: 50px;
  padding: 20px 25px;
  border-radius: 7px;
}
.ant-avatar {
  background-color: #ccc0;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
}