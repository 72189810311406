.radio-container {
    margin-top: 15px;
    cursor: pointer;
    align-items: center;
    display: flex;

    &.active svg, &.inactive svg {
        animation: bounce 0.5s ease; 
    }

    &.active svg {
        fill: #00AEEF; 
    }

    &.inactive svg {
        fill: #FFFFFF; 
        stroke: #00AEEF;
    }
}

@keyframes bounce {
    0%, 100% {
        transform: scale(1); 
    }
    50% {
        transform: scale(0.85);
    }
}

.radio-container[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.radio-label {
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 400;
    color: var(--Dark-Blue, #2B2D42);
    margin-left: 12px;
}