@import "../../styles//elements";

.logout-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #0079C1 !important;
    margin-left: 10px;
    margin-right: 50px;
    font-weight: 500;
}