.container {
    width: 100%;
    font-size: 18px;
}
.react-dropdown-select-dropdown {
  display: flex;
  flex-direction: column; 
  overflow-x: hidden !important;
  padding: 20px; 
  gap: 10px; 
  width: 500px !important; 
  border-width: 2px !important;
  border-color: #00AEEF !important;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.css-65sicv-DropdownHandleComponent,
.css-1bdfa7g-DropdownHandleComponent {
  color: #20639B !important;
  transform: scale(2); 
  margin-right: 10px !important;
}

.css-65sicv-DropdownHandleComponent svg,
.css-1bdfa7g-DropdownHandleComponent svg {
  width: 60px;
  height: 60px; 
  transform: scale(2);
}

.pdf-export-mode .css-65sicv-DropdownHandleComponent,
.pdf-export-mode .css-1bdfa7g-DropdownHandleComponent {
  display: none !important;
}

.css-oakzba-ReactDropdownSelect {
  border-width: 1px !important;
  border-color: #20639B !important;
}

.item-wrapper {
    display: flex;
    padding: 20px 20px 0 20px;
    width: 500px;
    cursor: auto;
  
    &:last-child {
      padding-bottom: 20px;
    }
  
    .labels-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .item-label {
        margin-left: 12px;
        color: var(--Dark-Blue, #2B2D42);
        font-family: "Roboto";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
  
      .end-label {
        text-decoration: underline;
        color: #00AEEF;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
  